/* EquipmentSlot.css */
.equipmentSlots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.equipmentSlot {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}