@keyframes floating-damage {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.floating-damage {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  color: red;
  animation: floating-damage 1.5s linear forwards;
}
