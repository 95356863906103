.loading-button {
  position: relative;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  width: 0;
  transition: width 0.1s linear;
}

.loading .progress-bar {
  animation-name: loadingAnimation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loadingAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
