.itemRectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}


.grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  position: relative;
  z-index: 1;
}

.polygon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}


.gridItem {
  width: 12.5%; /* Adjust this value to fit your desired grid size */
  height: 0;
  padding-bottom: 12.5%; /* Equal to the width percentage */
  position: relative;
  box-sizing: border-box;
  border: 1px solid #ccc;

  background-color: rgba(255, 0, 0, 0.1);
  outline: 1px solid rgba(0, 0, 255, 0.5);
}

.square {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.occupied .square {
  background-color: #f00; /* Change this to the desired color for occupied squares */
}
