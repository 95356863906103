.npc {
  position: relative;
  width: 100px;
  height: 50px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.npc-name {
  font-size: 14px;
  margin-bottom: 5px;
}

.npc-health-bar {
  background-color: #ccc;
  width: 80%;
  height: 5px;
}

.npc-health {
  height: 100%;
  background-color: #00b300;
}

/* Respawn pie animation */
.npc-respawn-pie {
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0, 100px, 50px, 50px);
  animation: pie-fade-out 1s linear infinite;
}

.pie-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  clip: rect(0, 50px, 100px, 0);
  animation: pie-fill 1s linear infinite;
}

.npc-container {
  display: flex;
  flex-wrap: wrap;
}

.npc-name {
  font-size: 14px;
  text-align: center;
}

@keyframes pie-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pie-fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
